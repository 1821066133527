import { useMemo } from "react";
import * as R from "ramda";

import {
  InstrumentTypesIdsEnum,
  PeriodTimeEnum,
  PlanDocumentStatusEnum,
  RelationshipTypesEnum,
  VestingTypesEnum,
} from "common/enums/enum";
import { OwnershipPlanForm } from "pages/equity-management/plans/wizards/create-one-off-plans/CreateGrantOneOffPlan";
import { PlanGetDTO } from "store/modelTypes";

import { createDateString } from "../components/atoms/DatePicker/DatePicker";
import { AvailableAmountAgreementsType } from "../types/Common.types";

type InitialValues = (Partial<PlanGetDTO> & { numberOfSharesLeft?: number }) | null;

const usePlanInitialValues = (companyId?: number | null, plan?: InitialValues) => {
  return useMemo<OwnershipPlanForm>(() => {
    const initial: OwnershipPlanForm & Partial<Record<"subscriptionAvailable", AvailableAmountAgreementsType>> = {
      companyId,
      planId: R.defaultTo(null, plan?.planId),

      programId: R.defaultTo(null, plan?.programId),
      saveAsDraft: false,
      //planReceiver
      firstName: R.defaultTo("", plan?.firstName),
      lastName: R.defaultTo("", plan?.lastName),
      isCompanyOwned: R.defaultTo(false, plan?.isCompanyOwned),
      phoneNumber: R.defaultTo("", plan?.phoneNumber),
      dateOfBirth: R.defaultTo("", plan?.dateOfBirth),
      address: R.defaultTo("", plan?.address),
      countryId: R.defaultTo(0, plan?.countryId),
      companyName: R.defaultTo("", plan?.companyName),
      organizationNumber: R.defaultTo("", plan?.organizationNumber),
      email: R.defaultTo("", plan?.email),
      relationshipTypeId: R.defaultTo(RelationshipTypesEnum.EMPLOYEE, plan?.relationshipTypeId),
      businessPostAddress: R.defaultTo("", plan?.businessPostAddress),
      existingPlan: R.defaultTo(false, plan?.existingPlan),
      //shareSource
      poolId: R.defaultTo(0, plan?.poolId),
      shareClassId: R.defaultTo(null, plan?.shareClassId),
      //sharesDetails
      planTypeId: R.defaultTo(InstrumentTypesIdsEnum.RSA, plan?.planTypeId),
      numberOfShares: R.defaultTo(undefined, plan?.numberOfShares),
      purchasePrice: R.defaultTo(undefined, plan?.purchasePrice),
      reason: R.defaultTo("", plan?.reason),
      exercisePrice: R.defaultTo(undefined, plan?.exercisePrice),
      optionsExpiration: R.defaultTo(undefined, plan?.optionsExpiration),
      optionsExpirationTimeUnit: R.defaultTo(PeriodTimeEnum.MONTH, plan?.optionsExpirationTimeUnit),
      //sharesDetailsMutator
      numberOfSharesLeft: R.defaultTo(-1, plan?.numberOfSharesLeft),
      //vestingConditions
      vestingTypeId: R.defaultTo(VestingTypesEnum.TIME_VESTING, plan?.vestingTypeId),
      conditions: R.defaultTo([], plan?.conditions),
      //vestingConditions
      allowAcceleratedVesting: R.defaultTo(false, plan?.allowAcceleratedVesting),
      vestingStartsAt: R.defaultTo(undefined, plan?.vestingStartsAt),
      vestingPeriod: R.defaultTo(undefined, plan?.vestingPeriod),
      vestingInterval: R.defaultTo(undefined, plan?.vestingInterval),
      vestingCliff: R.defaultTo(undefined, plan?.vestingCliff),
      timeVestedShares: R.defaultTo(0, plan?.timeVestedShares),
      //postTerminationExerciseClause
      retirement: R.defaultTo(undefined, plan?.retirement),
      retirementTimeUnit: R.defaultTo(PeriodTimeEnum.MONTH, plan?.retirementTimeUnit),
      disability: R.defaultTo(undefined, plan?.disability),
      disabilityTimeUnit: R.defaultTo(PeriodTimeEnum.MONTH, plan?.disabilityTimeUnit),
      terminationWithCause: R.defaultTo(undefined, plan?.terminationWithCause),
      terminationWithCauseTimeUnit: R.defaultTo(PeriodTimeEnum.MONTH, plan?.terminationWithCauseTimeUnit),
      involuntaryTermination: R.defaultTo(undefined, plan?.involuntaryTermination),
      involuntaryTerminationTimeUnit: R.defaultTo(PeriodTimeEnum.MONTH, plan?.involuntaryTerminationTimeUnit),
      voluntaryTermination: R.defaultTo(undefined, plan?.voluntaryTermination),
      voluntaryTerminationTimeUnit: R.defaultTo(PeriodTimeEnum.MONTH, plan?.voluntaryTerminationTimeUnit),
      byPassingAway: R.defaultTo(undefined, plan?.byPassingAway),
      byPassingAwayTimeUnit: R.defaultTo(PeriodTimeEnum.MONTH, plan?.byPassingAwayTimeUnit),
      isExcludedClause: R.defaultTo(false, plan?.excludePostTerminationClauses),
      //summaryMutator
      sendInvite: false,
      planAgreementDocument: null,
      documentStatusId: R.defaultTo(PlanDocumentStatusEnum.LATER, plan?.documentStatusId),
      fileAgreement: {
        newFile: null,
        oldFile: R.defaultTo(null, plan?.documentFile),
      },
      historicExercises: [],
      approvedAt: R.defaultTo(createDateString(new Date()), plan?.approvedAt),
      grantedAt: R.defaultTo(createDateString(new Date()), plan?.grantedAt),
      sendEmailInvitation: R.defaultTo(false, plan?.sendEmailInvitation),
      signingManagerEmail: R.defaultTo("", plan?.signingManagerEmail),
      documentsNeedsSignature: R.defaultTo(false, plan?.documentsNeedsSignature),
      stakeholderId: R.defaultTo(undefined, plan?.stakeholderId),
      essentialTerms: R.defaultTo("", plan?.essentialTerms),

      // new governing documents
      filesData: {
        files: [],
        oldFileIds: [],
      },

      // only for standalone RSA plans
      sharesFromStakeholderId: undefined,

      managerId: R.defaultTo(null, plan?.managerId),
      subscriptionAvailable: undefined,
      vestingRelativeExpiry: R.defaultTo(false, plan?.vestingRelativeExpiry),
    };

    return initial;
  }, [companyId, plan]);
};
export default usePlanInitialValues;
