import { FC, memo } from "react";

import { LinkHelpText, Ui } from "common/components/atoms";
import { InstrumentTypesNamesEnum } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../basics.module.scss";

const t = createTranslation(TranslationNS.pages, "createPlan.basics");

const Detail = memo(({ label, value, infoText }: { label: string; value: string; infoText?: string }) => {
  return (
    <div className={classes["vesting-detail"]}>
      <div>
        <Ui.s bold>{value}</Ui.s>

        <Ui.xs style={{ color: scssVariables.foregroundLow }}>{label}</Ui.xs>
      </div>

      <LinkHelpText content={infoText} title={label} tooltipWithTitle>
        <QuestionCircleIcon width={24} height={24} className={classes["icon"]} />
      </LinkHelpText>
    </div>
  );
});

const Details: FC = () => {
  const fNumber = useFormatNumbers();
  const selectedProgram = useStoreState((state) => state.programModel.program);

  const instrumentTypes = useStoreState((state) => state.common.dropdowns?.instrumentTypes);
  const selectedInstrumentType = instrumentTypes?.find((type) => type.id === selectedProgram?.instrumentTypeId);

  return (
    <div className="m-auto">
      <Ui.l bold className="mb-2">
        {t("detailsFromProgram")}
      </Ui.l>
      <div className={classes["vesting-details"]}>
        <Detail
          label={t("agreementType")}
          value={
            selectedInstrumentType?.name === InstrumentTypesNamesEnum.RSA ? "RSA" : selectedInstrumentType?.name || ""
          }
          infoText={selectedInstrumentType?.name === InstrumentTypesNamesEnum.RSA ? t("rsaInfo") : t("soInfo")}
        />
        <Detail
          label={t("pricePerShare")}
          value={fNumber(selectedProgram?.purchasePrice, "sharePrice")}
          infoText={t("pricePerShareInfo")}
        />
        <Detail
          label={t("vestingConditions")}
          value={t("vestingConditionsValue", {
            number: selectedProgram?.conditions?.length,
          })}
          infoText={t("vestingConditionsInfo")}
        />

        <Detail
          label={t("vestingPeriod")}
          value={t("vestingPeriodValue", {
            number: selectedProgram?.vestingPeriod || 0,
          })}
          infoText={t("vestingPreiodInfo")}
        />
        <Detail
          label={t("vestingInterval")}
          value={t("vestingIntervalValue", {
            number: selectedProgram?.vestingInterval || 0,
          })}
          infoText={t("vestingIntervalInfo")}
        />
        <Detail
          label={t("vestingCliff")}
          value={t("vestingCliffValue", {
            number: selectedProgram?.vestingCliff || 0,
          })}
          infoText={t("vestingCliffInfo")}
        />
      </div>
    </div>
  );
};

export default Details;
