import { FC, useCallback } from "react";

import { useStoreActions } from "store/store";

import PoolsAndProgramsStore from "../store";
import EditProgramSlidePanel from "./EditProgramSlidePanel/EditProgramSlidePanel";

const EditProgram: FC = () => {
  const getPoolsAndProgramsThunk = useStoreActions((state) => state.company.getPoolsAndProgramsThunk);

  const editingProgramState = PoolsAndProgramsStore.useStoreState((state) => state.editingProgram);
  const { setEditingItem } = PoolsAndProgramsStore.useStoreActions((actions) => actions);

  const offCanvasSaveHandler = useCallback((): void => {
    getPoolsAndProgramsThunk().then();
  }, [getPoolsAndProgramsThunk]);

  const offCanvasCloseHandler = useCallback((): void => {
    setEditingItem(undefined);
  }, [setEditingItem]);

  const show = !!editingProgramState;
  return (
    <EditProgramSlidePanel
      show={show}
      onHide={offCanvasCloseHandler}
      programId={editingProgramState?.id}
      afterSave={offCanvasSaveHandler}
    />
  );
};

export default EditProgram;
