import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import Check from "common/components/atoms/Checks/Check";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import TextArea from "common/components/atoms/TextArea/TextArea";
import TextField from "common/components/atoms/TextField/TextField";
import useCurrency from "common/hooks/useCurrency";
import { PercentageIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "../ConvertibleLoanEditPanel.module.scss";
import useConvertibleLoanEditPanel from "../useConvertibleLoanEditPanel";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan.editPanel");

const LoanTerms: FC = () => {
  const { initialValues, shareClassesCompany } = useConvertibleLoanEditPanel();
  const { currencySymbol } = useCurrency();

  const { values, errors, touched, handleChange, setFieldValue, handleBlur } = useFormikContext<typeof initialValues>();

  useEffect(() => {
    if (values.isShareClassRequired === "no") {
      setFieldValue("shareClassId", undefined);
    } else {
      setFieldValue("shareClassDescription", "");
    }
  }, [setFieldValue, values.isShareClassRequired]);

  const handleChangeDate = (date: Date | string, name?: string) => {
    if (name) {
      setFieldValue(name, date);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <TextField
            min={0}
            type="number"
            label={t("loanAmount")}
            value={values.loanAmount}
            error={errors.loanAmount}
            isTouched={touched.loanAmount}
            name={"loanAmount"}
            onChange={handleChange}
            onBlur={handleBlur}
            iconRight={currencySymbol}
          />
        </Col>
        <Col>
          <TextField
            min={0}
            step={0.01}
            type="number"
            label={t("discount")}
            value={values.discount}
            error={errors.discount}
            isTouched={touched.discount}
            name="discount"
            onChange={handleChange}
            onBlur={handleBlur}
            iconRight={<PercentageIcon color={scssVariables.foregroundMedium} />}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <TextField
            min={0}
            step={0.01}
            type="number"
            label={t("interestRate")}
            value={values.interestRate}
            error={errors.interestRate}
            isTouched={touched.interestRate}
            name="interestRate"
            onChange={handleChange}
            onBlur={handleBlur}
            iconRight={<PercentageIcon color={scssVariables.foregroundMedium} />}
          />
        </Col>
        <Col>
          <TextField
            min={0}
            isOptional
            type="number"
            label={t("valuationCap")}
            error={errors.valuationCap}
            isTouched={touched.valuationCap}
            value={values.valuationCap}
            name="valuationCap"
            onChange={handleChange}
            onBlur={handleBlur}
            iconRight={currencySymbol}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <TextField
            min={0}
            isOptional
            type="number"
            label={t("conversionPrice")}
            value={values.conversionPrice}
            error={errors.conversionPrice}
            isTouched={touched.conversionPrice}
            name="conversionPrice"
            onChange={handleChange}
            onBlur={handleBlur}
            iconRight={currencySymbol}
          />
        </Col>
        <Col>
          <DatePicker
            isDateOnlyString
            label={t("agreementDate")}
            date={values.agreementDate}
            className={classes["date-picker"]}
            onChange={handleChangeDate}
            onBlur={handleBlur}
            name="agreementDate"
            error={errors.agreementDate}
            isTouched={touched.agreementDate}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DatePicker
            isDateOnlyString
            label={t("expirationDate")}
            date={values.expirationDate}
            className={classes["date-picker"]}
            onChange={handleChangeDate}
            onBlur={handleBlur}
            name="expirationDate"
            error={errors.expirationDate}
            isTouched={touched.expirationDate}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputHeader className="mt-4" label={t("shareClass")} />
          <Check
            type="radio"
            label={t("shareClassAgreed")}
            name="isShareClassRequired"
            value="yes"
            checked={values.isShareClassRequired === "yes"}
            onChange={handleChange}
          />
          <Check
            type="radio"
            label={t("selectShareClassLater")}
            name="isShareClassRequired"
            value="no"
            checked={values.isShareClassRequired === "no"}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        {values.isShareClassRequired === "yes" ? (
          <Col md={6}>
            <Dropdown
              className="mt-4"
              label={t("shareClass")}
              placeholder={t("notSelected")}
              error={errors.shareClassId}
              isTouched={touched.shareClassId}
              selectedValue={values.shareClassId}
              options={shareClassesCompany || []}
              optionsIsObject
              name="shareClassId"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        ) : (
          <Col md={12}>
            <TextArea
              isOptional
              name="shareClassComment"
              label={t("shareClassDescription")}
              value={values.shareClassComment}
              onChange={handleChange}
              placeholder={t("shareClassDescriptionPl")}
              rows={3}
              className="mt-4"
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default LoanTerms;
