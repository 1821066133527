import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { defaultTo } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, Ui } from "common/components/atoms";
import { SettingsIcon, UserIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { scssVariables } from "../../utils/constants";
import classes from "./Card.module.scss";

const t = createTranslation(TranslationNS.pages, "company.multiCompaniesDashboard");

const DetailsCard: FC<JSX.IntrinsicElements["div"] & Partial<{ setWidth?: (value: number) => void }>> = ({
  setWidth,
  className,
  ...props
}) => {
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement | null>(null);

  const { user } = useStoreState((state) => state.account);

  const AccountRoutes = useMemo(
    () => [
      {
        route: getPath(["user", "general"]),
        title: t("manageProfile"),
        icon: <UserIcon strokeWidth={1} />,
      },
      {
        route: getPath(["user", "account"]),
        title: t("settingsProfile"),
        icon: <SettingsIcon />,
      },
    ],
    []
  );

  const viewPortfolioHandler = useCallback(() => {
    navigate(getPath(["myPortfolio", "portfolio"]));
  }, [navigate]);

  const resizeHandler = useCallback(() => {
    if (setWidth && ref.current) {
      const timerID = setTimeout(() => {
        setWidth(ref.current?.getBoundingClientRect()?.width || 0);
      }, 300);

      return () => {
        clearTimeout(timerID);
      };
    }
  }, [setWidth]);

  useEffect(() => {
    if (ref.current && setWidth) {
      setWidth(ref.current?.getBoundingClientRect()?.width);
    }

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler, setWidth]);

  return (
    <div ref={ref} className={classNames(classes["card"], className)} {...props}>
      <div className="d-flex align-items-center">
        {user?.profileImage ? (
          <Image src={user?.profileImage} className={classes["image"]} alt="profile" />
        ) : (
          <div
            className={classNames(classes["image"], {
              [classes["isEmpty"]]: true,
            })}
          >
            <UserIcon />
          </div>
        )}

        <div className="ms-3">
          <H.xxs className="mb-1">{`${user?.firstName} ${user?.lastName}`}</H.xxs>

          <Ui.s
            title={defaultTo("", user?.email)}
            className={classNames(classes["user-email"], "w-75 text-truncate")}
            style={{ textDecoration: "underline" }}
          >
            {t("profileEmail", {
              email: user?.email,
            })}
          </Ui.s>
        </div>
      </div>
      <div className={`${classes["account-section"]} mt-8 mb-10`}>
        {AccountRoutes.map(({ route, icon, title }) => (
          <Link to={route} className={classes["link"]} key={route}>
            {icon} <Ui.s className="ms-1 ui-s">{title}</Ui.s>
          </Link>
        ))}
      </div>
      <div className={classes["support-section"]}>
        <Ui.s className="mb-4" style={{ color: scssVariables.foregroundLow }}>
          {t.el("contactUs", {
            components: [
              <a
                key="1"
                target="_blank"
                href="https://www.unlisted.ai/contact"
                className={classes["link"]}
                rel="noreferrer"
              />,
            ],
          })}
        </Ui.s>

        <Button size="s" onClick={viewPortfolioHandler}>
          {t("viewPortfolio")}
        </Button>
      </div>
    </div>
  );
};

export default DetailsCard;
