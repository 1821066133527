import { FC } from "react";

import { GetStakeholdersDTO } from "../../types";
import Row from "./row";
import classes from "./table-view.module.scss";

type PropsTypes = {
  stakeholders: GetStakeholdersDTO;
  onEdit: (id: number) => void;
  onClick?: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
};

const TableBody: FC<PropsTypes> = ({ stakeholders, onEdit, onClick, handleRemoveStakeholder }) => {
  return (
    <tbody>
      {stakeholders.map((props) => (
        <Row
          key={props.stakeholderId}
          {...props}
          onEdit={onEdit}
          onClick={onClick}
          handleRemoveStakeholder={handleRemoveStakeholder}
          className={classes.row}
        />
      ))}
    </tbody>
  );
};

export default TableBody;
