import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { Check, Helper } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

import { newPlanFieldsNames as f } from "../../../../../../../../common/plan/planFormFields";
import ProgramHelper from "../../../../../wizards/create-program/components/ProgramHelper";
import SpentProgressBar from "../../../../../wizards/create-program/components/SpentProgressBar/SpentProgressBar";
import ProgramEssentialTermsField from "../../../../../wizards/create-program/programFields/ProgramEssentialTermsField";
import ProgramExercisePriceField from "../../../../../wizards/create-program/programFields/ProgramExercisePriceField";
import ProgramNumberOfShares from "../../../../../wizards/create-program/programFields/ProgramNumberOfShares";
import ProgramOptionsExpirationDateFields from "../../../../../wizards/create-program/programFields/ProgramOptionsExpirationDateFields";
import ProgramPoolField from "../../../../../wizards/create-program/programFields/ProgramPoolField";
import ProgramPurchasePriceField from "../../../../../wizards/create-program/programFields/ProgramPurchasePriceField";
import ProgramTypeField from "../../../../../wizards/create-program/programFields/ProgramTypeField";
import { CreateProgramFormData } from "../../../../../wizards/create-program/useProgramForm";

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.editProgram");

type ShareDetailsFieldsProps = {
  companyId?: string;
  poolIsDisabled?: boolean;
};
const ShareDetailsFields: FC<ShareDetailsFieldsProps> = ({ companyId, poolIsDisabled }) => {
  const { values, handleChange } = useFormikContext<CreateProgramFormData>();

  return (
    <Helper>
      <ProgramHelper colInputSize={6} questionId={"poolId"}>
        <ProgramPoolField companyId={companyId} isDisabled={poolIsDisabled} />
      </ProgramHelper>

      <ProgramTypeField />

      <ProgramHelper className="mt-4" colInputSize={6} questionId={["numberOfShares"]}>
        <ProgramNumberOfShares />
        <SpentProgressBar
          className="mt-1"
          title={t("poolStatus.title")}
          valueText={t("poolStatus.valueText")}
          now={values.numberOfShares || 0}
          max={values?.numberOfSharesLeft || 0}
        />
      </ProgramHelper>

      <ProgramHelper className="mt-4" colInputSize={6} questionId={["exercisePrice", "purchasePrice"]}>
        {values.programTypeId === InstrumentTypesIdsEnum.OPTION ? <ProgramExercisePriceField /> : null}
        <ProgramPurchasePriceField />
      </ProgramHelper>

      {values.programTypeId === InstrumentTypesIdsEnum.OPTION && (
        <ProgramHelper className="mt-4" colInputSize={8} questionId={"optionsExpiration"}>
          <ProgramOptionsExpirationDateFields />
        </ProgramHelper>
      )}
      {values.programTypeId === InstrumentTypesIdsEnum.OPTION && (
        <Row className="mt-3">
          <Col>
            <Helper.Question questionId={f.vestingRelativeExpiry}>
              <Check
                name="vestingRelativeExpiry"
                label="The expiry date is relative to vesting date"
                checked={values.vestingRelativeExpiry}
                onChange={handleChange}
              />
            </Helper.Question>
            <Helper.Answer
              answerId={f.vestingRelativeExpiry}
              text="When checked, the expiry date for stock options will be calculated based on stock options exercise period after each vesting date, rather than after the vesting period begins."
              className="mt-1"
            />
          </Col>
        </Row>
      )}

      <ProgramHelper className="mt-3" colInputSize={16} questionId={"essentialTerms"}>
        <ProgramEssentialTermsField />
      </ProgramHelper>
    </Helper>
  );
};
export default ShareDetailsFields;
