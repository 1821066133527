import { FC } from "react";

import { Ui } from "common/components/atoms/Typography";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.table");

const TableHead: FC = () => {
  return (
    <thead>
      <tr>
        <th>
          <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("stakeholder")}</Ui.s>
        </th>
        <th>
          <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("email")}</Ui.s>
        </th>
        <th>
          <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("relationship")}</Ui.s>
        </th>
        <th></th>
      </tr>
    </thead>
  );
};

export default TableHead;
