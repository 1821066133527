import { FC, Fragment } from "react";
import cn from "classnames";
import { defaultTo, isEmpty } from "ramda";

import { Button, H, NewAvatar, P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CommonFileIcon, PlusIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionCategory, TransactionStatus } from "../../../../types";
import { TransactionContextMenuHandlers } from "../body";
import classes from "../transaction-item.module.scss";

type PropsTypes = {
  transaction: Transaction;
  contextMenuHandlers?: TransactionContextMenuHandlers;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const IssueSharesAndBuySellBody: FC<PropsTypes> = ({ contextMenuHandlers, transaction }) => {
  const fNumber = useFormatNumbers();
  const isTransactionInBundle = !!transaction.transactionBundleId;

  return (
    <>
      <div className={cn("p-4 mb-2", classes.bodyContainer, classes.darkBackground)}>
        {/* Avatars with transactions */}
        <div className={cn("d-flex justify-content-between pb-3", classes.border)}>
          {transaction.categoryId === TransactionCategory.Sell && (
            <>
              <div className={cn("d-flex align-items-center", classes["top-section"])}>
                <NewAvatar
                  className={classes["avatar"]}
                  imageUrl={transaction.fromAvatar}
                  company={transaction.fromIsCompanyOwned}
                  initials={transaction.fromInitials}
                />

                <div className="ms-2">
                  <H.xxs className="mb-1">{transaction.fromName || "-"}</H.xxs>
                  {transaction.fromRepresentativeName && (
                    <Ui.s className="mb-2 text-medium">{transaction.fromRepresentativeName}</Ui.s>
                  )}
                  <Ui.xl className="mt-1" bold style={{ color: scssVariables.critical500 }}>
                    {transaction.categoryId === TransactionCategory.Sell
                      ? `- ${fNumber(transaction.numberOfShares, "amount")} shares`
                      : "-"}
                  </Ui.xl>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <ArrowRightIcon width={24} height={24} color={scssVariables.foregroundLow} />
              </div>
            </>
          )}

          <div className={cn("d-flex align-items-center", classes["top-section"])}>
            <NewAvatar
              className={classes["avatar"]}
              imageUrl={transaction.toAvatar}
              company={transaction.toIsCompanyOwned}
              initials={transaction.toInitials}
            />

            <div className="ms-2">
              <H.xxs className="mb-1">{transaction.toName}</H.xxs>
              {transaction.toRepresentativeName && (
                <Ui.s className="mb-2 text-medium">{transaction.toRepresentativeName}</Ui.s>
              )}

              <Ui.xl className="mt-1" bold style={{ color: scssVariables.positive700 }}>
                {`+ ${fNumber(transaction.numberOfShares, "amount")}`} shares
              </Ui.xl>
            </div>
          </div>
        </div>

        {/* Transaction info */}
        <div className={"d-flex flex-column py-3"}>
          {/* Description */}
          {transaction?.description && (
            <P.s
              color="foregroundLow"
              className={cn("mb-3 pb-3")}
              dangerouslySetInnerHTML={{
                __html: transaction?.description?.replace(/\r\n/g, "<br />"),
              }}
            />
          )}

          <div className="d-flex justify-content-between mb-3 flex-wrap gap-3">
            <div className={cn("px-3", classes["info-section"])}>
              <Ui.s className="mb-1" color="foregroundLow">
                {t("numberOfShares")}
              </Ui.s>
              <Ui.xl bold>{fNumber(transaction.numberOfShares, "amount")}</Ui.xl>
            </div>

            <div className={cn("px-3", classes["info-section"])}>
              <Ui.s className="mb-1" color="foregroundLow">
                {t("sharePrice")}
              </Ui.s>
              <Ui.xl bold>{fNumber(transaction.sharePrice, "sharePrice")}</Ui.xl>
            </div>

            <div className={cn("px-3", classes["info-section"])}>
              <Ui.s className="mb-1" color="foregroundLow">
                {t("transactionTotal")}
              </Ui.s>
              <Ui.xl bold>{fNumber(transaction.transactionTotal, "amount")}</Ui.xl>
            </div>

            <div className={cn("px-3", classes["info-section"])}>
              <Ui.s className="mb-1" color="foregroundLow">
                {t("shareClass")}
              </Ui.s>
              <Ui.xl bold>{transaction.shareClassName}</Ui.xl>
            </div>

            {/* Shares series */}
            {transaction.shareSeries && transaction.shareSeries.length > 0 && (
              <div
                className={cn("px-3", classes["info-section"], {
                  [classes["long"]]: true,
                })}
              >
                <Ui.s className="mb-2" color="foregroundMedium">
                  {t("shareNumbers")
                    ?.split(" ")
                    ?.map((el, index) => (
                      <Fragment key={el}>
                        <span style={{ textTransform: index === 0 ? "capitalize" : undefined }}>{el}</span>{" "}
                      </Fragment>
                    ))}
                </Ui.s>
                <div className="d-flex flex-wrap gap-1">
                  {transaction.shareSeries.map((range) => (
                    <Tag variant="closed" key={range}>
                      <Ui.s>
                        {range
                          .split("-")
                          .map((number) => fNumber(number, "amount"))
                          .join(" - ")}
                      </Ui.s>
                    </Tag>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={cn("mt-3 px-3 py-2", classes["documents"])}>
          {!isEmpty(defaultTo([], transaction?.files)) ? (
            <div className="d-flex flex-wrap">
              {defaultTo([], transaction?.files).map((el) => {
                return (
                  <div
                    key={el.downloadId}
                    className={cn("d-flex align-items-center", classes["single-document"])}
                    onClick={() => {
                      openRemoteDocument(el?.downloadId);
                    }}
                  >
                    <CommonFileIcon fontSize={24} className="me-2" />
                    <Ui.s>{el.fileName}</Ui.s>
                  </div>
                );
              })}
            </div>
          ) : (
            <Button
              size="s"
              variant="tertiary"
              onClick={() => {
                contextMenuHandlers?.handleOpenManageDocumentsTransaction?.(transaction);
              }}
              iconLeft={<PlusIcon />}
            >
              Add documents
            </Button>
          )}
        </div>
      </div>
      {transaction.categoryId === TransactionCategory.Issue &&
        transaction.statusId === TransactionStatus.Confirmed &&
        !isTransactionInBundle && (
          <Ui.s color="foregroundLow" className={cn("py-2 px-4", classes.bodyContainer, classes.darkBackground)}>{`${t(
            "totalSharesAfterTransactions"
          )}: ${fNumber(transaction.numbersOfSharesInCompany, "amount")}`}</Ui.s>
        )}
    </>
  );
};
export default IssueSharesAndBuySellBody;
