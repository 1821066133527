import { FC, useEffect, useMemo, useRef } from "react";
import cn from "classnames";
import { defaultTo } from "ramda";

import { LinkHelpText, NewAvatar, Table, Tag, Ui } from "common/components/atoms";
import { CapTableStakeholderDetailsType } from "common/components/organisms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowLeftIcon, ArrowRightIcon, CommonFileIcon, TimeIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { TransactionCategory, TransactionStatus } from "pages/equity-management/ownership/transactions/types";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDetailsContext } from "../../stakeholder-details.context";
import classes from "../../stakeholder-details.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.transactions");

const Head = () => {
  const rows = useMemo(
    () => [
      {
        key: "date",
        value: t("head.date"),
      },
      {
        key: "stakeholders",
        value: t("head.stakeholders"),
      },

      {
        key: "shares",
        value: t("head.shares"),
      },
      {
        key: "price",
        value: t("head.price"),
      },
      {
        key: "total",
        value: t("head.total"),
      },
      {
        key: "transaction-type",
        value: t("head.transactionType"),
      },
      {
        key: "event-type",
        value: t("head.eventType"),
      },
      {
        key: "description",
        value: t("head.description"),
      },
    ],
    []
  );

  return (
    <thead className={classes["head-row"]} style={{ width: 1300 }}>
      <tr>
        {rows.map((el, i) => (
          <th
            key={el.key}
            className={cn(classes["head-item"], classes["no-border"])}
            style={{
              width:
                i === 0
                  ? "12%"
                  : i === 1
                  ? "18%"
                  : i === 2 || i === 3 || i === 4
                  ? "10%"
                  : i === 5
                  ? "15%"
                  : i === 6
                  ? "20%"
                  : "5%",

              height: 40,
            }}
          >
            <div
              className={cn("d-flex align-items-center fw-500", {
                "justify-content-end": i === 2 || i === 3 || i === 4,
              })}
            >
              <Ui.xs>{el.value}</Ui.xs>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Body: FC<{ data: CapTableStakeholderDetailsType["transactions"] }> = ({ data }) => {
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const fNumber = useFormatNumbers();

  const sortedData = (data || []).sort((a, b) => {
    const firstDate = new Date(a.transactedAt).getTime();
    const secondDate = new Date(b.transactedAt).getTime();

    return secondDate - firstDate;
  });

  useEffect(() => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTo(0, 0);
    }
  }, []);

  return (
    <tbody ref={tableBodyRef}>
      {sortedData.length ? (
        data.map((el) => (
          <tr key={el.transactionId} className={cn(classes.tableBorder, classes["no-border"])}>
            <td style={{ width: "12%" }}>
              <Ui.s>{transformDateToCommonDateFormat(el.transactedAt, "d. MMM yyyy")?.toLowerCase()}</Ui.s>
            </td>

            <td style={{ width: "18%" }}>
              {el.categoryId === TransactionCategory.Issue ? (
                "-"
              ) : (
                <div className="d-flex align-items-center">
                  <div title={el.stakeholderName}>
                    <NewAvatar
                      size="s"
                      imageUrl={el.stakeholderAvatar || undefined}
                      company={el.stakeholderIsCompanyOwned}
                      initials={el.stakeholderInitials ? el.stakeholderInitials : el.stakeholderName}
                    />
                  </div>

                  {el.stakeholderIsBuying ? <ArrowLeftIcon className="mx-1" /> : <ArrowRightIcon className="mx-1" />}

                  <Ui.s>
                    {el.transactedIsCompanyOwned
                      ? el.transactedRepresentativeName
                      : el.transactedName
                      ? el.transactedName
                      : ""}
                  </Ui.s>
                </div>
              )}
            </td>

            <td style={{ width: "10%" }} className="text-end">
              <Ui.s>{fNumber(el.numberOfShares, "amount")}</Ui.s>
            </td>

            <td style={{ width: "10%" }} className="text-end">
              <Ui.s>{fNumber(el.sharePrice, "value", { customCurrencySymbol: el.currencySymbol })}</Ui.s>
            </td>

            <td style={{ width: "10%" }} className="text-end">
              <Ui.s>{fNumber(el.transactionTotal, "value", { customCurrencySymbol: el.currencySymbol })}</Ui.s>
            </td>

            <td style={{ width: "15%" }}>
              <Tag variant={el.statusId === TransactionStatus.Pending ? "information" : "complete"}>
                {el.statusId === TransactionStatus.Pending ? <TimeIcon className="me-1" /> : null}

                {el.categoryName}
              </Tag>
            </td>

            <td style={{ width: "20%" }}>
              <Ui.s>{el.typeName}</Ui.s>
            </td>

            <td style={{ width: "5%" }}>
              {el.description ? (
                <LinkHelpText placement="left" title="Description" content={el.description}>
                  <CommonFileIcon fontSize={24} color={scssVariables.foregroundLow} />
                </LinkHelpText>
              ) : null}
            </td>
          </tr>
        ))
      ) : (
        <tr className={cn(classes.tableBorder, { [classes["no-border"]]: true })} style={{ height: 80 }}>
          <td width="100%" colSpan={10}>
            <Ui.s color="foregroundLow" className="text-start">
              {t("empty")}
            </Ui.s>
          </td>
        </tr>
      )}
    </tbody>
  );
};

const TransactionsTab: FC = () => {
  const details = StakeholderDetailsContext.useStoreState((state) => state.details);

  return (
    <div className={cn("m-4", classes["details-container"])}>
      <div className={cn(classes["head"])} style={{ backgroundColor: scssVariables.element2 }}>
        <div className="d-flex justify-content-between align-items-center px-3">
          <div className="text-start">
            <div className="d-flex align-items-center">
              <Ui.m bold>{t("title")}</Ui.m>

              <div className={classes["badge"]}>
                <Ui.xs>{details?.transactions?.length}</Ui.xs>
              </div>
            </div>

            <Ui.xs color="foregroundLow">{t("description")}</Ui.xs>
          </div>
        </div>
      </div>

      <div style={{ width: "100%", overflow: "auto" }}>
        <Table
          className={cn(classes["table"], {
            [classes["long-table"]]: defaultTo([], details?.transactions).length > 0,
          })}
          headComponent={<Head />}
          bodyComponent={<Body data={defaultTo([], details?.transactions)} />}
        />
      </div>
    </div>
  );
};

export default TransactionsTab;
